import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import Faq from "../../components/faq-accordian";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const BrakesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "brakes" }) {
				slug
				brakesFields {
					brakesBanner {
						brakesBannerHeading
						brakesBannerBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					brakesContentSection {
						brakesContentHeading
						brakesContentLeft
						brakesContentRight
					}
					brakesFullWidthImage {
						brakesSectionImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					brakesLeftImageRightContent {
						brakesRightContentHeading
						brakesRightContentText
						brakesRightContentButton {
							title
							target
							url
						}
						brakesLeftImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					brakesReviews {
						brakesReviewsHeading
					}
					brakesServicesCards {
						brakesServicesCardsHeading
						brakesServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
					brakesForm {
						brakesFormHeading
					}
					brakesFaqSection {
						brakesFaqSectionHeading
						brakesFaqSectionDescription
						brakesFaqs {
							brakesAnswer
							brakesQuestion
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, brakesFields, slug },
	} = data;

	const {
		brakesBanner,
		brakesContentSection,
		brakesFullWidthImage,
		brakesLeftImageRightContent,
		brakesReviews,
		brakesServicesCards,
		brakesForm,
		brakesFaqSection,
	} = brakesFields;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Brakes",
				item: {
					url: `${siteUrl}/what-we-do/brakes`,
					id: `${siteUrl}/what-we-do/brakes`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{brakesBanner && (
					<section>
						<ServiceHero
							title={brakesBanner.brakesBannerHeading}
							backgroundImage={
								brakesBanner.brakesBannerBackgroundImage.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={brakesBanner.brakesBannerBackgroundImage.node?.altText}
						/>
					</section>
				)}
				{brakesContentSection &&
					!checkPropertiesForNull(brakesContentSection, [
						"brakesContentHeading",
					]) && (
						<section className="py-5  py-lg-8 position-relative">
							<Container>
								<Row>
									<Col className="position-relative">
										<h2 className="text-center pb-5">
											{brakesContentSection.brakesContentHeading}
										</h2>
									</Col>
								</Row>
								<Row>
									<Col lg={6}>
										<SafeHtmlParser
											htmlContent={brakesContentSection?.brakesContentLeft}
										/>
									</Col>
									<Col lg={6}>
										<SafeHtmlParser
											htmlContent={brakesContentSection?.brakesContentRight}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{brakesFullWidthImage && (
					<section>
						<GatsbyImage
							image={
								brakesFullWidthImage.brakesSectionImage?.node.localFile
									.childImageSharp.gatsbyImageData
							}
							alt=""
						/>
					</section>
				)}

				{brakesLeftImageRightContent &&
					!checkPropertiesForNull(brakesLeftImageRightContent, [
						"brakesRightContentHeading",
					]) && (
						<div className="py-5 py-lg-8">
							<ImageSideContainer
								image={
									brakesLeftImageRightContent.brakesLeftImage.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								imageAlt={
									brakesLeftImageRightContent.brakesLeftImage.node?.altText
								}
								title={brakesLeftImageRightContent.brakesRightContentHeading}
								order="first"
								imagePadding=" pb-5 pb-lg-0    "
								text={brakesLeftImageRightContent.brakesRightContentText}
								ctaButton={
									brakesLeftImageRightContent?.brakesRightContentButton
								}
							/>
						</div>
					)}
				{brakesFaqSection &&
					!checkPropertiesForNull(brakesFaqSection, [
						"brakesFaqSectionHeading",
						"brakesFaqs",
					]) && (
						<section className="py-5 py-lg-8 bg-background">
							<Container className="">
								<Row className="justify-content-center">
									<Col lg={10} className="text-center pb-6">
										<h2 className="text-white pb-4">
											{brakesFaqSection.brakesFaqSectionHeading}
										</h2>
										<p className="text-white">
											<SafeHtmlParser
												htmlContent={
													brakesFaqSection?.brakesFaqSectionDescription
												}
											/>
										</p>
									</Col>
								</Row>
								{brakesFaqSection.brakesFaqs &&
									brakesFaqSection.brakesFaqs.length > 0 && (
										<Row>
											{brakesFaqSection.brakesFaqs.map((faqItem) => (
												<Faq
													question={faqItem.brakesQuestion}
													answer={
														<SafeHtmlParser
															htmlContent={faqItem?.brakesAnswer}
														/>
													}
												/>
											))}
										</Row>
									)}
							</Container>
						</section>
					)}
				<section>
					<Reviews title={brakesReviews?.brakesReviewsHeading} />
				</section>
				<section id="book" className="pb-5  pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{brakesForm?.brakesFormHeading || "BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Brakes" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {brakesServicesCards &&
          !checkPropertiesForNull(brakesServicesCards, [
            "brakesServicesCardsHeading",
            "brakesServicesCards",
          ]) && (
            <OurServicesSection
              title={brakesServicesCards.brakesServicesCardsHeading}
              services={brakesServicesCards.brakesServicesCards?.nodes}
            />
          )} */}
			</Layout>
		</>
	);
};

export default BrakesPage;
